import React from "react";
import "../style/style.css"; // Archivo CSS para estilos

const LluviaDeSobres = () => {
  return (
    <div className="lluvia-de-sobres-container">
      <div className="lluvia-de-sobres-content">
        <div className="lluvia-de-sobres-icon">✉️</div>
        <div className="lluvia-de-sobres-title">Lluvia de Sobres</div>
        <div className="lluvia-de-sobres-icon">✉️</div>
      </div>
    </div>
  );
};

export default LluviaDeSobres;
