import React, { useState, useEffect } from "react";
import "../style/style.css";
import programImage from "../assets/img/Progama.png";
import programaImageMobile from "../assets/img/Progama_mobile.png";

const ProgramSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="program-section-container">
      <img
        src={isMobile ? programaImageMobile : programImage}
        alt="Programa"
        className="program-section-image"
      />
    </div>
  );
};

export default ProgramSection;
