import React, { useState, useEffect } from "react";
import "../style/style.css";

const WeddingInfo = () => {
  const calculateTimeLeft = () => {
    const weddingDate = new Date("2024-12-21T15:00:00");
    const now = new Date();
    const difference = weddingDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        months: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
        days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="countdown-container">
      <div className="countdown-content">
        <div className="countdown-title">¡Y empezó la cuenta regresiva!</div>
        <div className="time-blocks">
          <div className="time-block">
            <div className="time-value">{timeLeft.months || 0}</div>
            <div className="time-label">Meses</div>
          </div>
          <div className="divider"></div>
          <div className="time-block">
            <div className="time-value">{timeLeft.days || 0}</div>
            <div className="time-label">Días</div>
          </div>
          <div className="divider"></div>
          <div className="time-block">
            <div className="time-value">{timeLeft.hours || 0}</div>
            <div className="time-label">Horas</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeddingInfo;
