import React from "react";

import "../App.css";
import logo from "../assets/img/Logo.png"; // Asegúrate de que esta ruta sea correcta

const Navbar = () => {
  return (
    <nav className="navbar">
      {/* Franja superior verde */}
      <div className="green-bar">
        <div className="name-block">
          <h3>Paula</h3>
          <div className="logo-container">
            <img src={logo} alt="Wedding Logo" className="logo" />
          </div>
          <h3>Miguel</h3>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
