import React, { useState, useEffect } from "react";
import "../style/style.css";
import quoteImageDesktop from "../assets/img/Frase.png";
import quoteImageMobile from "../assets/img/Frase_mobile.png";

const QuoteSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="quote-section-container">
      <img
        src={isMobile ? quoteImageMobile : quoteImageDesktop}
        alt="Frase"
        className="quote-section-image"
      />
    </div>
  );
};

export default QuoteSection;
