import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Invitation from "./components/Invitation";
import WeddingInfo from "./components/WeddingInfo";
import DressCode from "./components/DressCode";
import QuoteSection from "./components/QuoteSection";
import ProgramSection from "./components/ProgramSection";
import LocationSection from "./components/LocationSection";
import RSVP from "./components/RSVP";
import Banner from "./components/Banner";
import PlaylistSection from "./components/Playlist";
import Footer from "./components/FooterSection";
import LluviaDeSobres from "./components/LluviaDeSobres";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Element } from "react-scroll";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Hero />
                <Invitation />
                <WeddingInfo />
                <DressCode />
                <LluviaDeSobres />
                <QuoteSection />
                <Element name="program-section">
                  <ProgramSection />
                </Element>
                <Element name="location-section">
                  <LocationSection />
                </Element>
                <Element name="rsvp-section">
                  <RSVP />
                </Element>
                <Banner />
                <LluviaDeSobres />
                <Element name="playlist-section">
                  <PlaylistSection />
                </Element>
                <Footer />
              </>
            }
          />
          {/* Agrega más rutas aquí si necesitas otras páginas */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
