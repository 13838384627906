import React from "react";

const PlaylistSection = () => {
  const playlistId = "1qUse8FxFSmxgbmVFW8Hp0";
  const playlistUrl =
    "https://open.spotify.com/playlist/1qUse8FxFSmxgbmVFW8Hp0?si=b659d87e6ce44e54&pt=ea420a6f24bbf2bc8c3a4ecece676fa0";

  return (
    <div className="playlist-section">
      <div className="title">
        Queremos que formes parte de cada momento
        <span>
          , <br />
          agrega tu canción favorita a la playlist.
        </span>
      </div>
      <br />
      <div className="button-container">
        <a href={playlistUrl} target="_blank" rel="noopener noreferrer" className="playlist-button">
          Agregar canción
        </a>
      </div>
      <div className="add-song">
        <iframe
          title="Spotify Embed: Recommendation Playlist"
          src={`https://open.spotify.com/embed/playlist/${playlistId}?utm_source=generator&theme=0`}
          width="100%"
          height="100%"
          style={{ minHeight: "360px" }}
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default PlaylistSection;
