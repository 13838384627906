import React from "react";
import { Link } from "react-scroll";
import "../App.css";
import ArcoImg from "../assets/img/arco_invita.png"; // Asegúrate de que la ruta sea correcta

const Invitation = () => {
  return (
    <div className="invitation-container">
      <div className="invitation-header">
        <div className="invitation-content">
          <div className="invitation-title">
            <h2>Acompáñanos</h2>
            <div className="invitation-subtitle">
              <h3>ESTAS INVITADO</h3>
            </div>
          </div>
          <div className="invitation-text">
            El amor es un viaje, no un destino...
            <br />
            <br />
            Por esta razón, hemos decidido recorrer este viaje juntos y queremos compartir este
            momento con las personas que más amamos.
            <br />
            <br />
            Acompáñanos en la celebración de nuestro amor y compromiso.
            <br />
            <br />
            Tu presencia añadirá un toque especial a nuestra alegría.
          </div>
          <Link to="rsvp-section" className="menu-item" smooth={true} duration={500}>
            <button className="invitation-button">Confirma tu asistencia</button>
          </Link>
        </div>
      </div>
      <div className="invitation-image">
        <img src={ArcoImg} alt="Arco de Invitación" />
      </div>
    </div>
  );
};

export default Invitation;
