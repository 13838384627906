import React, { useState, useEffect } from "react";
import HeroImgDesktop from "../assets/img/Hero.png";
import HeroImgMobile from "../assets/img/Hero_mobile.png";
import "../style/style.css";

const Hero = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="hero">
      <div className="hero-image">
        <img src={isMobile ? HeroImgMobile : HeroImgDesktop} alt="Wedding Hero" />
        <div className="hero-overlay" />
      </div>
    </div>
  );
};

export default Hero;
