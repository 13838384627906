import React from "react";
import "../style/style.css"; // Archivo CSS para estilos
import logo from "../assets/img/Logo.png"; //

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="green-bar-2">
        <div className="name-block">
          <h5 className="footer-title ">Nuestra Boda</h5>
          <div className="logo-container">
            <img src={logo} alt="Wedding Logo" className="logo" style={{ height: "30px" }} />
          </div>
          <h5 className="footer-title ">21 Dic. 2024</h5>
        </div>
      </div>

      <div className="footer-section" style={{ background: "white" }}>
        <div className="footer-content">
          <div className="footer-item">
            <div className="footer-couple">Paula & Miguel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
