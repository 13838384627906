import React from "react";
import "../style/style.css";
import mapImage from "../assets/img/mapImage.png"; // Asegúrate de tener una imagen del mapa en esta ubicación

const LocationSection = () => {
  const openInWaze = () => {
    const wazeLink = "waze://?ll=4.78780469%2C-74.09825563&navigate=yes";
    window.location.href = wazeLink;
  };

  const openInGoogleMaps = () => {
    const googleMapsLink = "https://www.google.com/maps?q=4.78707,-74.09812";
    window.location.href = googleMapsLink;
  };

  return (
    <div className="location-section-container">
      <div className="location-section-content">
        <h2>Hacienda Casa Torano</h2>
        <p>
          Cra. 135 #171a-81, Bogotá
          <br />
          Vía Conejera - Variante Cota
        </p>
        <img src={mapImage} alt="Mapa de la ubicación" className="location-section-map" />

        <div className="location-section-buttons">
          <button onClick={openInWaze} className="location-section-button">
            Waze
          </button>
          <button onClick={openInGoogleMaps} className="location-section-button">
            Google Maps
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
