import React, { useState, useEffect, useCallback } from "react";
import "../style/style.css";

const RSVP = () => {
  const initialState = {
    _id: "",
    name: "",
    phone: "",
    attending: "",
    dietaryRestrictions: "",
    dietaryDetails: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [inputSuggestions, setInputSuggestions] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [messageColor, setMessageColor] = useState("red");

  const API_URL = process.env.REACT_APP_API;

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\d{9,}$/;
    return phoneRegex.test(phone);
  };

  const fetchSuggestions = useCallback(
    async (name) => {
      if (name.length < 2) {
        setInputSuggestions([]);
        return;
      }
      try {
        const response = await fetch(`${API_URL}/api/invitados/autocomplete?name=${name}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setInputSuggestions(data.map((guest) => guest.name));
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setInputSuggestions([]);
      }
    },
    [API_URL],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const normalizedFormName = formData.name.toLowerCase();

    if (!isValidPhoneNumber(formData.phone)) {
      setConfirmationMessage("Por favor, ingresa un número de teléfono válido.");
      setMessageColor("red");
      return;
    }

    const isInGuestList = await validateGuest(normalizedFormName);

    if (!isInGuestList) {
      setConfirmationMessage("El nombre no está en la lista de invitados.");
      setMessageColor("red");
      return;
    }

    try {
      const url = `${API_URL}/api/invitados/${formData._id}`; // Solo PUT es permitido

      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      setFormData(initialState);
      setConfirmationMessage("Confirmación exitosa.");
      setMessageColor("green");
    } catch (error) {
      console.error("Error submitting RSVP:", error);
      setConfirmationMessage("Error al enviar la confirmación.");
      setMessageColor("red");
    }
  };

  useEffect(() => {
    fetchSuggestions(formData.name);
  }, [formData.name, fetchSuggestions]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSuggestionClick = async (suggestion) => {
    try {
      const response = await fetch(`${API_URL}/api/invitados/autocomplete?name=${suggestion}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const guestData = data.find((guest) => guest.name === suggestion);
      if (guestData) {
        setFormData({
          _id: guestData._id,
          name: guestData.name,
          phone: guestData.phone || "",
          attending: guestData.attending || "",
          dietaryRestrictions: guestData.dietaryRestrictions || "",
          dietaryDetails: guestData.dietaryDetails || "",
        });
      }
      setInputSuggestions([]);
    } catch (error) {
      console.error("Error fetching guest data:", error);
    }
  };

  const validateGuest = async (name) => {
    try {
      const response = await fetch(`${API_URL}/api/invitados/autocomplete?name=${name}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.some((guest) => guest.name.toLowerCase() === name.toLowerCase());
    } catch (error) {
      console.error("Error validating guest:", error);
      return false;
    }
  };

  return (
    <div className="rsvp-section-container">
      <h2>Confirma tu asistencia</h2>
      <form onSubmit={handleSubmit} className="rsvp-form">
        <div className="rsvp-form-group">
          <input
            type="text"
            name="name"
            placeholder="Nombre Completo"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {inputSuggestions.length > 0 && (
            <div className="suggestions-list">
              {inputSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="invitation-text"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="rsvp-form-group">
          <input
            type="text"
            name="phone"
            placeholder="Teléfono"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <select
            name="attending"
            value={formData.attending || ""}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              ¿Asistirás a la boda?
            </option>
            <option value="true">Sí</option>
            <option value="false">No</option>
          </select>
          <select
            name="dietaryRestrictions"
            value={formData.dietaryRestrictions || ""}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              ¿Tienes restricciones alimentarias?
            </option>
            <option value="true">Sí</option>
            <option value="false">No</option>
          </select>
        </div>
        {formData.dietaryRestrictions === "true" && (
          <textarea
            name="dietaryDetails"
            placeholder="¿Cuáles son tus restricciones alimentarias?"
            value={formData.dietaryDetails}
            onChange={handleChange}
            required
          />
        )}
        {confirmationMessage && <p style={{ color: messageColor }}>{confirmationMessage}</p>}
        <button type="submit" className="rsvp-submit-button">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default RSVP;
