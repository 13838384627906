import React from "react";
import "../style/style.css";
import banner from "../assets/img/Banner.png";

const Banner = () => {
  return (
    <div className="banner-container">
      <img src={banner} alt="Banner" className="banner-image" />
    </div>
  );
};

export default Banner;
